#hamburger {
  width: 3rem;
  height: 2rem;
  border-radius: 5px;
  position: absolute;
  z-index: 99;
  top: 22%;
  right: 8%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.hamburger-line {
  width: 2rem;
  height: 0.1rem;
  border: 0.5px solid gray;
  background-color: gray;
  border-radius: 5px;
  z-index: 99;
  top: 1.7rem;
}

.hamburger-menu {
  width: 100%;
  height: 3rem;
  border: 0.5px solid white;
  background-color: white;
  z-index: 10;
  margin-top: -0.3rem;
  justify-content: space-around;
  position: relative;
  top: -3rem;
  transition: top 0.5s ease;
}

#hamburger-nav {
  visibility: hidden;
  font-family: "Montserrat";
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  color: #3d3c2a;
  margin: 0;
  position: absolute;
  width: 100%;
}

#hamburger-nav span {
  font-size: 13px;
  display: block;
  padding: 5px;
  margin: 10px 2rem;
  height: 1rem;
  width: 33%;
}

.visible {
  top: 0rem;
}

.hamburger-menu-load {
  display: none;
}
